import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createQuoteRequest } from "../actions/requestQuoteAction";
import { REQUEST_QUOTE_RESET } from "../constants/requestQuoteConstants";
import swal from "sweetalert";
import emailjs from "emailjs-com";

const RequestQuote = () => {
  const dispatch = useDispatch();
  const form = useRef();

  const createQuote = useSelector((state) => state.createQuote);
  const {
    success: successRequest,
    error: errorRequest,
    loading: loadingRequest,
  } = createQuote;

  const [companyName, setCompanyName] = useState("");
  const [position, setPosition] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [service, setService] = useState("");
  const [description, setDescription] = useState("");

  const [errorRequest1, setErrorRequest1] = useState(false);

  useEffect(() => {
    if (errorRequest) {
      setErrorRequest1(true);
    }
    setTimeout(() => {
      setErrorRequest1(false);
    }, 1000);
  }, [errorRequest]);

  if (successRequest) {
    swal({
      title: "Your Request have been submitted successfully!",
      icon: "success",
      button: "Close",
    });
  }

  if (errorRequest1) {
    swal({
      title: `${errorRequest}`,
      icon: "error",
      button: "Close",
    });
  }

  useEffect(() => {
    if (successRequest === true) {
      dispatch({
        type: REQUEST_QUOTE_RESET,
      });
      setCompanyName("");
      setPosition("");
      setEmail("");
      setPhoneNumber("");
      setService("");
      setDescription("");
    } else if (errorRequest) {
      setTimeout(() => {
        dispatch({
          type: REQUEST_QUOTE_RESET,
        });
      }, 5000);
    }
  }, [successRequest, dispatch, errorRequest]);

  const submitHandler = (e) => {
    e.preventDefault(e);
    dispatch(createQuoteRequest(companyName, email, service, description));
    // emailjs
    //   .sendForm(
    //     "service_czlj7hs",
    //     "template_sbym0r6",
    //     form.current,
    //     "LSzAkP5R6YerQQpXk"
    //   )
    //   .then(
    //     (result) => console.log(result.text),
    //     (error) => console.log(error.text)
    //   );
    emailjs
    .sendForm(
      "service_w15guun",
      "template_0lkidwz",
      form.current,
      "khD2cY4-rYdUSWa4Y"
    )
    .then(
      (result) => console.log(result.text),
      (error) => console.log(error.text)
    );
  };

  return (
    <div>
      <section className="contact-info-area ">
        <div className="row">
          <div
            className="col-xl-12 col-lg-12 col-md-12 col-sm-12"
            style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <div className="contact-form">
              <div className="row">
                <div className="col-xl-12">
                  <div className="sec-title-style1 float-left">
                    <div className="title" data-aos="fade-left">
                      REQUEST FOR CONSULTATION
                    </div>
                    <div className="text">
                      <div className="decor-left">
                        <span></span>
                      </div>
                      <p>Do more faster</p>
                    </div>
                  </div>
                  <div className="text-box float-right">
                    <h3>
                      Do More faster, with{" "}
                      <span style={{ color: "#f04217" }}>Outcess </span>{" "}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="inner-box">
                <form id="contact-form" ref={form} onSubmit={submitHandler}>
                  <div className="row">
                    <div className="col-xl-6 col-lg-12">
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="input-box">
                            <input
                              className="input-box-textarea"
                              type="text"
                              placeholder="Company/Full Name"
                              required="true"
                              name="companyName"
                              id="companyName"
                              value={companyName}
                              onChange={(e) => setCompanyName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-xl-12">
                          <div className="input-box">
                            <input
                              className="input-box-textarea"
                              type="text"
                              placeholder="Position / Designation"
                              required="true"
                              name="position"
                              id="position"
                              value={position}
                              onChange={(e) => setPosition(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-xl-12">
                          <div className="input-box">
                            <input
                              className="input-box-textarea"
                              type="email"
                              name="email"
                              id="email"
                              placeholder="Email Address"
                              required="true"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-xl-12">
                          <div className="input-box">
                            <input
                              className="input-box-textarea"
                              type="text"
                              name="phoneNumber"
                              id="phoneNumber"
                              placeholder="Phone Number"
                              required="true"
                              value={phoneNumber}
                              onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="input-box">
                            <select
                              className="input-box-textarea"
                              type="text"
                              required
                              name="service"
                              id="service"
                              value={service}
                              onChange={(e) => setService(e.target.value)}>
                              <option>Please Select Service</option>
                              {[
                                "Business Process Outsourcing",
                                "Business Productivity Solutions",
                                "Cloud Solutions",
                                "Customer Engagement Solutions",
                                "Omni-Channel Digital Marketing",
                                "Remote Contact Center",
                                "I.T Services",
                              ].map((item) => (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-12">
                      <div className="input-box">
                        <textarea
                          className="input-box-textarea"
                          placeholder="Please describe what you need..."
                          required="true"
                          name="message"
                          id="message"
                          value={description}
                          onChange={(e) =>
                            setDescription(e.target.value)
                          }></textarea>
                      </div>
                      <div>
                        <button
                          disabled={loadingRequest && true}
                          type="submit"
                          value="Request">
                          {loadingRequest
                            ? "submiting Request..."
                            : "Request Consultation"}
                          <span className="flaticon-next"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RequestQuote;
