import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { BsClock, BsTelephone } from "react-icons/bs";
import { FiMapPin } from "react-icons/fi";
import RequestQuote from "../components/RequestQuote";

const RequestForQuote = () => {
  return (
    <div>
      <Navbar />
      <main>
        <div>
          <section className="contact-address-area">
            <div className="container">
              <div className="sec-title-style1 text-center max-width">
                <div className="title" data-aos="fade-down">
                  REQUEST CONSULTATION
                </div>
                <div className="text">
                  <div className="decor-left">
                    <span></span>
                  </div>
                  <p>Do more faster</p>
                  <div className="decor-right">
                    <span></span>
                  </div>
                </div>
                <div className="bottom-text ">
                  <p>
                    For over 15 years we've been able to compete, grow and
                    prosper in an accelerating world.
                  </p>
                </div>
              </div>
              <div className="contact-address-box row">
                {/* <!--Start Single Contact Address Box--> */}
                <div
                  className="col-sm-4 single-contact-address-box text-center"
                  data-aos="fade-right">
                  <h1 className="BsClock">
                    <BsClock />
                  </h1>
                  <h2>
                    Monday-Friday <br />
                    9: 00am - 6: 00pm
                  </h2>
                </div>
                {/* <!--End Single Contact Address Box-->
                <!--Start Single Contact Address Box--> */}
                <div className="col-sm-4 single-contact-address-box main-branch">
                  {/* <h3>Outcess Solutions</h3> */}
                  <div className="inner">
                    <div className="FiMapPin">
                      <h1>
                        <FiMapPin />
                      </h1>
                      <p>75 Opebi Road.Ikeja Lagos</p>
                    </div>
                  </div>
                </div>
                {/* <!--End Single Contact Address Box-->
                <!--Start Single Contact Address Box--> */}
                <div
                  className="col-sm-4 single-contact-address-box text-center"
                  data-aos="fade-left">
                  <h1 className="BsTelephone">
                    <BsTelephone />
                  </h1>
                  <h2>
                    02013300308 <br /> info @outcess.com
                  </h2>
                </div>
                {/* <!--End Single Contact Address Box--> */}
              </div>
            </div>
          </section>
          {/* <!--End Contact Address Area-->

          <!--Start contact form area--> */}
          <RequestQuote />
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default RequestForQuote;
